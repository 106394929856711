<template>
    <span :class="$style.ClaimReqsLoading"></span>
</template>

<script setup lang="ts"></script>

<style lang="scss" module>
.ClaimReqsLoading {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: 12px 34px;
    position: relative;
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    box-sizing: border-box;
    animation: shadowPulse 1s linear infinite;
}

@keyframes shadowPulse {
    33% {
        background: #FFF;
        box-shadow: -24px 0 $brand-green, 24px 0 #FFF;
    }
    66% {
        background: $brand-green;
        box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    }
    100% {
        background: #FFF;
        box-shadow: -24px 0 #FFF, 24px 0 $brand-green;
    }
}
</style>
